import { useState } from 'react';
import CopyToClipboard from 'react-copy-to-clipboard';
import styled from 'styled-components';
import CopyContent from '../../assets/svgs/copy-content.svg';
import CopyContentTwo from '../../assets/images/verified.png';

interface ListImagesProps {
  setModalImage: (arg0: boolean) => void;
  setImageSelected: (arg0: string) => void;
  imageLink: string;
  name: string;
  index?: number;
}

const ListImages = ({
  setModalImage,
  setImageSelected,
  imageLink,
  index,
  name,
}: ListImagesProps) => {
  const [copy, setCopy] = useState<boolean>(false);
  const [copied, setCopied] = useState<boolean>(false);

  const openImage = (image: string) => {
    setModalImage(true);
    setImageSelected(image);
  };

  return (
    <ListImagesContainer>
      <Styled onClick={() => openImage(imageLink)}>
        <Image src={imageLink}></Image>
        <TextImage>
          <h1>{name.substring(14, name.length)}</h1>
          <p>{imageLink}</p>
        </TextImage>
      </Styled>

      <CopyToClipboard text={imageLink} onCopy={() => setCopied(true)}>
        <ImageTwo
          onClick={() => setCopy(true)}
          src={copy ? CopyContentTwo : CopyContent}
        />
      </CopyToClipboard>
    </ListImagesContainer>
  );
};

export default ListImages;

const ListImagesContainer = styled.div`
  display: flex;
  cursor: pointer;
`;

const TextImage = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  margin-top: 20px;
  font-family: 'Rubik', sans-serif;
  font-style: normal;
  font-weight: 350;
  font-size: 14px;
  line-height: 22px;
  color: #8798ad;
  max-width: 300px;
  margin-left: 20px;
  text-align: left;
  word-wrap: break-word;

  p {
    margin-top: 20px;
  }
`;

const ImageTwo = styled.div<{ src: string }>`
  background-image: url(${(props) => props.src});
  background-size: cover;
  background-position: center;
  width: 20px;
  height: 20px;
  border-radius: 6px;
  animation-name: fade;
  animation-duration: 1s;
  margin-top: 30px;
`;

const Image = styled.div<{ src: string }>`
  position: relative;
  background-image: url(${(props) => props.src});
  background-size: cover;
  background-position: center;
  min-width: 130px;
  width: 130px;
  height: 130px;
  border-radius: 6px;
  margin-top: 20px;
  animation-name: fade;
  animation-duration: 1s;
  cursor: pointer;

  @keyframes fade {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
`;

const Styled = styled.div`
  display: flex;

  :hover {
    cursor: pointer;
  }
`;
