import React, { useState } from 'react';
import styled from 'styled-components';
import ClosePhoto from '../../../assets/svgs/close-photo.svg';
import CopyContent from '../../../assets/svgs/copy-content.svg';
import CopyContentTwo from '../../../assets/images/verified.png';
import CopyToClipboard from 'react-copy-to-clipboard';

type Props = {
  isOpen: boolean;
  onClose: () => void;
  image: string;
};

const ModalHandleImage: React.FC<Props> = ({
  isOpen,
  onClose,
  image,
}: Props) => {
  const [copy, setCopy] = useState<boolean>(false);
  const [copied, setCopied] = useState<boolean>(false);
  return (
    <ScreenContainer isVisible={isOpen}>
      <Image src={image}>
        <ButtonRemoveImage
          onClick={() => {
            setCopy(false);
            onClose();
          }}
        >
          <Img src={ClosePhoto} />
        </ButtonRemoveImage>
      </Image>
      <StyledLink>
        <h1>{image}</h1>
        <CopyToClipboard text={image} onCopy={() => setCopied(true)}>
          <ImageTwo
            onClick={() => setCopy(true)}
            src={copy ? CopyContentTwo : CopyContent}
          />
        </CopyToClipboard>
      </StyledLink>
    </ScreenContainer>
  );
};
export default ModalHandleImage;

const ScreenContainer = styled.div<{ isVisible: boolean }>`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background-color: #00000080;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  opacity: ${(props) => (props.isVisible ? 1 : 0)};
  pointer-events: ${(props) => (props.isVisible ? 'auto' : 'none')};
  z-index: 1005;
  h1 {
    font-family: 'Rubik', sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 18px;
    color: #080606;
    margin-bottom: 20px;
    max-width: 400px;
  }
`;

const Image = styled.div<{ src: string }>`
  position: relative;
  background-image: url(${(props) => props.src});
  background-size: cover;
  background-position: center;
  width: 400px;
  height: 400px;
  border-radius: 6px;
  margin: 10px;
  animation-name: fade;
  animation-duration: 1s;

  @keyframes fade {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
`;
const ButtonRemoveImage = styled.span`
  width: 20px;
  height: 20px;
  border-radius: 10px;
  background: none;
  position: absolute;
  top: -25px;
  right: -25px;
  display: flex;
  justify-content: center;
  align-items: center;
`;
const Img = styled.img`
  width: 24px;
  object-fit: contain;
  margin-left: 8px;
  cursor: pointer;
`;

const ImageTwo = styled.div<{ src: string }>`
  background-image: url(${(props) => props.src});
  background-size: cover;
  background-position: center;
  width: 20px;
  height: 20px;
  border-radius: 6px;
  animation-name: fade;
  animation-duration: 1s;
  margin-top: 10px;
  cursor: pointer;
`;

const StyledLink = styled.div`
  display: flex;
  background: #fafafa;
  max-width: 470px;
  width: 100%;
  padding: 20px;
  border-radius: 20px;
  padding-bottom: 10px;
`;
