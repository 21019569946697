import { useState } from 'react';
import styled from 'styled-components';
import AcessAcount from '../../components/Modals/AccessAcount/AccessAcount';
import useUnprotectedPage from '../../hooks/useUnProtectedPage';

const Login = (): JSX.Element => {
  useUnprotectedPage();
  const [steps, setSteps] = useState<number>(3);
  return (
    <Main>
      <Header />

      <Content>
        <BackgroundComponent />
        <BackgroundText>
          <p>Organização</p>
          <p>Rastreabilidades</p>
          <p>Transparência</p>
        </BackgroundText>
        <Title>Upload de imagens de forma simples</Title>
        <Container>
          <AcessAcount forgetPassword={() => setSteps(2)} />
        </Container>
      </Content>
      <Footer>
        <span>Um produto Máquina do Bem</span>
      </Footer>
    </Main>
  );
};

export default Login;

const Main = styled.div`
  width: 100%;
  height: 100vh;
  background-color: ${(props) => props.theme.colors.primary};
  padding-bottom: 48px;
  overflow-x: hidden;
`;

const Header = styled.header`
  width: 100%;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: end;

  a {
    margin: 10px;
    font-family: 'Rubik', sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 10px;
    color: ${(props) => props.theme.colors.white};
    text-decoration: none;
  }

  @media (max-width: 298px) {
    a {
      text-align: center;
      line-height: 15px;
    }
  }
`;

const Content = styled.div`
  position: relative;
  height: 100vh;
`;

const Title = styled.h1`
  font-family: 'Poppins', sans-serif;
  font-size: 50px;
  font-style: normal;
  font-weight: 700;
  line-height: 72px;
  letter-spacing: 0em;
  text-align: center;
  width: 50%;
  margin: 0 auto;
  margin-bottom: 60px;
  color: ${(props) => props.theme.colors.white};

  @media (max-width: 500px) {
    width: 100%;
    font-size: 40px;
    line-height: 50px;
  }
`;

const Footer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 110px;

  span {
    font-family: 'Rubik', sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 10px;
    color: #ffffff;
  }

  @media (max-width: 767px) {
    margin-top: 150px;
  }
`;

const Container = styled.div`
  margin: 0 auto;
`;

const BackgroundText = styled.div`
  position: absolute;
  color: #ffffff;
  text-align: right;
  right: 40px;
  bottom: -120px;
  p {
    font-family: 'Poppins', sans-serif;
    font-size: 36px;
    font-style: normal;
    font-weight: 700;
    line-height: 36px;
    letter-spacing: 0em;
    text-align: right;
  }

  @media (max-width: 373px) {
    p {
      font-size: 30px;
    }
  }

  @media (max-width: 310px) {
    p {
      font-size: 26px;
    }
  }
`;

const BackgroundComponent = styled.div`
  position: absolute;
  width: 150%;
  height: 60%;
  left: -80px;
  top: 230px;
  background: ${(props) => props.theme.colors.white};
  border: 1px solid #e6eaf6;
  transform: rotate(-10.92deg);
  overflow-y: hidden;
  @media (max-width: 749px) {
    top: 280px;
  }

  @media (max-width: 657px) {
    left: -80px;
    top: 290px;
  }

  @media (max-width: 499px) {
    left: -60px;
    top: 180px;
    height: 70%;
  }

  @media (max-width: 307px) {
    height: 70%;
    top: 240px;
  }
`;
