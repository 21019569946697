import { useState, useEffect } from 'react';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';
import { getAll } from '../../services/index';
import Loading from '../../components/Loading/Loading';
import ModalHandleImage from '../../components/Modals/ModalHandleImage/ModalHandleImage';
import Pagination from '../../components/Pagination/Pagination';
import useProtectedPage from '../../hooks/useProtectedPage';
import { isImage, isVideo } from '../../services/utils';
import VideoIcon from '../../assets/images/video-icon.png';

interface ResponseImage {
  key: string;
  lastModified: string;
  link: string;
  size: number;
  storageClass: string;
}

const Galery = (): JSX.Element => {
  useProtectedPage();
  const navigate = useNavigate();
  const [modalImage, setModalImage] = useState<boolean>(false);
  const [image, setImage] = useState<ResponseImage[]>([]);
  const [loading, setLoading] = useState(false);
  const [imageSelected, setImageSelected] = useState<string>('');
  const [pg, setPg] = useState<number>(0);
  const pp = 10;

  useEffect(() => {
    setLoading(true);
    getAll()
      .then((res) => {
        setImage(res.data);
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setLoading((prev) => !prev);
      });
  }, []);

  const openImage = (image: string) => {
    setModalImage(true);
    setImageSelected(image);
  };

  const pages: number = Math.ceil(image ? image.length / pp : 0);
  const startIndex = pg * pp;
  const endIndex = startIndex + pp;
  const current: any | undefined = image?.slice(startIndex, endIndex);

  return (
    <Main>
      <ModalHandleImage
        isOpen={modalImage}
        onClose={() => setModalImage(false)}
        image={imageSelected}
      />
      <Content>
        <BackgroundComponent />
        <Title>Adicionadas recentemente</Title>
        <Container>
          <ContentStyled>
            {loading && <Loading />}
            {!loading && image.length === 0 && (
              <TextImage>Nenhum arquivo encontrado!</TextImage>
            )}
            {current.length > 0 && (
              <>
                <ImagesContainer>
                  {current.map((image: ResponseImage, index: number) => {
                    return (
                      <Styled key={`image-galery-${index}`}>
                        {isImage(image.link) ? (
                          <Image
                            onClick={() => openImage(image.link)}
                            src={image.link}
                          ></Image>
                        ) : (
                          <Image
                            src={VideoIcon}
                            onClick={() => window.open(image.link)}
                          ></Image>
                        )}

                        <TextImage>
                          {image.key.substring(14, 29)}
                          {image.key.length > 30 ? '...' : ''}
                        </TextImage>
                      </Styled>
                    );
                  })}
                </ImagesContainer>
                <PaginationStyled>
                  <Pagination
                    pages={pages}
                    pg={pg}
                    setPg={setPg}
                    lastPage={pages}
                    total={image ? image.length : 0}
                  />
                </PaginationStyled>
              </>
            )}

            <Button onClick={() => navigate('/')}>Voltar</Button>
          </ContentStyled>
        </Container>
      </Content>
      <Footer>
        <span>Um produto Máquina do Bem</span>
      </Footer>
    </Main>
  );
};
export default Galery;

const Main = styled.div`
  width: 100%;
  height: 100vh;
  background-color: ${(props) => props.theme.colors.primary};
  padding-bottom: 48px;
  overflow-x: hidden;
`;

const Content = styled.div`
  position: relative;
  height: 100vh;
`;

const BackgroundComponent = styled.div`
  position: absolute;
  width: 150%;
  height: 60%;
  left: -80px;
  top: 230px;
  background: ${(props) => props.theme.colors.white};
  border: 1px solid #e6eaf6;
  transform: rotate(-10.92deg);
  overflow-y: hidden;
  @media (max-width: 749px) {
    top: 280px;
  }

  @media (max-width: 657px) {
    left: -80px;
    top: 290px;
  }

  @media (max-width: 499px) {
    left: -60px;
    top: 180px;
    height: 70%;
  }

  @media (max-width: 307px) {
    height: 70%;
    top: 240px;
  }
`;

const Title = styled.h1`
  font-family: 'Poppins', sans-serif;
  font-size: 50px;
  font-style: normal;
  font-weight: 700;
  line-height: 72px;
  letter-spacing: 0em;
  text-align: center;
  width: 50%;
  margin: 0 auto;
  margin-bottom: 60px;
  padding-top: 100px;
  position: relative;
  z-index: 999;
  color: ${(props) => props.theme.colors.white};
  @media (max-width: 500px) {
    width: 100%;
    font-size: 40px;
    line-height: 50px;
  }
`;

const Container = styled.div`
  margin: 0 auto;
`;

const TextImage = styled.p`
  font-family: 'Rubik', sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 10px;
  color: #8798ad;
  margin-bottom: 20px;
`;

const Footer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 110px;

  span {
    font-family: 'Rubik', sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 10px;
    color: white;
  }

  @media (max-width: 767px) {
    margin-top: 150px;
  }
`;

const ImagesContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-evenly;
  margin-top: 20px;
  flex-wrap: wrap;
`;

const Img = styled.img`
  width: 20px;
  height: 20px;
  border-radius: 10px;
  position: absolute;
  top: -5px;
  right: -5px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
`;

const Image = styled.div<{ src: string }>`
  position: relative;
  background-image: url(${(props) => props.src});
  background-size: cover;
  background-position: center;
  width: 130px;
  height: 130px;
  border-radius: 6px;
  margin: 10px;
  animation-name: fade;
  animation-duration: 1s;
  cursor: pointer;

  @keyframes fade {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
`;

const ContentStyled = styled.div`
  max-width: 880px;
  background: ${(props) => props.theme.colors.white};
  padding: 42px 32px 32px 32px;
  text-align: center;
  position: relative;
  border-radius: 6px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  border: 1px solid #707175;
`;

const Button = styled.button`
  width: 100%;
  height: 49px;
  font-size: 14px;
  color: #ffffff;
  font-weight: 500;
  font-family: ${(props) => props.theme.text.title.fontFamily};
  background-color: ${(props) => props.theme.colors.primary};
  border: none;
  border-radius: 8px;
  transition: 0.3s;
  margin-top: 20px;
  :hover {
    cursor: pointer;
    filter: brightness(1.1);
  }
  &:disabled {
    cursor: not-allowed;
    opacity: 0.3;
  }
`;

const Styled = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const PaginationStyled = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
  margin-top: 40px;
`;
