import styled, { css } from 'styled-components';
import { useState } from 'react';
import * as yup from 'yup';
import { Formik } from 'formik';
import { ReactComponent as ViewIcon } from '../../../assets/icons/view.svg';
import { ReactComponent as HiddenIcon } from '../../../assets/icons/hidden.svg';
import { useNavigate } from 'react-router-dom';
import Checkbox from '../../Checkbox/Checkbox';
import PasswordInput from '../../PasswordInput/PasswordInput';
import Input from '../../Input/Input';
import { login } from '../../../services/index';
import { toast } from 'react-toastify';
import Spinner from '../../Spinner/Spinner';

type FormType = {
  email: string;
  password: string;
};

interface RequimentsPasswordProps {
  itsSafe: boolean;
}

interface AccessAcountInterface {
  forgetPassword: () => void;
}

export const AccessAccount = ({ forgetPassword }: AccessAcountInterface) => {
  const [isDisabled, setIsDisabled] = useState<boolean>(false);
  const [viewPassword, setViewPassword] = useState<boolean>(false);
  const [password, setPassword] = useState<string>('password');
  const [isChecked, setIsChecked] = useState<boolean>(
    localStorage.getItem('email') ? true : false,
  );
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const navigate = useNavigate();

  const initialValues: FormType = {
    email: localStorage.getItem('email') || '',
    password: localStorage.getItem('password') || '',
  };

  const FormSchema = yup.object().shape({
    email: yup
      .string()
      .required('Coloque seu e-mail por favor.')
      .email('Coloque um e-mail válido.'),
    password: yup
      .string()
      .required('É necessario uma senha 😉')
      .min(6, 'A senha deve ter no mínimo 6 caracteres.')
      .max(20, 'A senha deve ter no máximo 20 caracteres.'),
  });

  const handleSubmit = async (values: FormType) => {
    setIsDisabled(true);
    setIsLoading(true);

    const data = {
      email: values.email,
      password: values.password,
    };
    await login(data.email, data.password)
      .then((res) => {
        localStorage.setItem('token', res.data.data.date);
        if (isChecked) {
          localStorage.setItem('email', values.email);
          localStorage.setItem('password', values.password);
        } else if (!isChecked) {
          localStorage.removeItem('email');
          localStorage.removeItem('password');
        }
        setIsDisabled(false);
        setIsLoading(false);
        navigate('/');
      })
      .catch((err) => {
        setIsDisabled(false);
        setIsLoading(false);
        toast.error('Email ou senha incorretos!');
      });
  };

  const handleViewPassword = () => {
    if (password === 'password') {
      setViewPassword(!viewPassword);
      setPassword('text');
    } else {
      setViewPassword(!viewPassword);
      setPassword('password');
    }
  };

  return (
    <Container>
      <Content>
        <Header>
          {/* <ShortLogoMdb className="logo" /> */}
          <h1>Acesse sua conta</h1>
          <span>Insira seus dados para continuar.</span>
        </Header>
        <Formik
          initialValues={initialValues}
          onSubmit={handleSubmit}
          validationSchema={FormSchema}
          isInitialValid={
            localStorage.getItem('email') && localStorage.getItem('password')
              ? true
              : false
          }
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
            isValid,
          }) => {
            return (
              <form onSubmit={handleSubmit}>
                <Field>
                  <Label>E-mail</Label>
                  <Input
                    type="email"
                    placeholder="Digite seu email"
                    value={values.email}
                    onBlur={handleBlur('email')}
                    onChange={handleChange('email')}
                    autoComplete="off"
                    error={touched.email ? errors.email : undefined}
                  />
                </Field>
                <Field>
                  <LabelPassword>Senha</LabelPassword>
                  <PasswordInput
                    type={password}
                    placeholder="**********"
                    name="password"
                    minLength={8}
                    value={values.password}
                    onBlur={handleBlur('password')}
                    onChange={handleChange('password')}
                    autoComplete="off"
                  />
                  <ButtonStyled>
                    <button type="button" onClick={() => handleViewPassword()}>
                      {viewPassword ? (
                        <HiddenIcon className="hidden" />
                      ) : (
                        <ViewIcon className="view" />
                      )}
                    </button>
                  </ButtonStyled>
                  <FeedbackError>
                    {touched.password ? errors.password : undefined}
                  </FeedbackError>
                </Field>

                <Button type="submit" disabled={!isValid || isDisabled}>
                  {isLoading ? <Spinner /> : 'Entrar'}
                </Button>
              </form>
            );
          }}
        </Formik>
      </Content>
    </Container>
  );
};

export default AccessAccount;

const Container = styled.main`
  margin: 0 auto;
`;

const Content = styled.section`
  max-width: 480px;
  background: #ffffff;
  padding: 42px 32px 32px 32px;
  text-align: center;
  position: relative;
  border-radius: 6px;
  margin: 0 auto;
  border: 1px solid #8798ad;
  h1 {
    font-family: ${(props) => props.theme.text.title.fontFamily};
    font-weight: normal;
    font-style: normal;
    font-size: 24px;
    line-height: 32px;
    color: ${(props) => props.theme.colors.black};
  }
  p {
    font-family: ${(props) => props.theme.text.title.fontFamily};
    font-weight: normal;
    font-style: normal;
    font-size: 14px;
    line-height: 20px;
    color: ${(props) => props.theme.colors.colorNeutralLight};
    padding-top: 17px;
    padding-bottom: 51px;
  }
  i {
    color: #8798ad;
    font-weight: bold;
    cursor: pointer;
    position: absolute;
    right: 0;
    top: 0;
    padding-right: 25px;
    padding-top: 26px;
  }
`;

const Header = styled.header`
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  align-items: center;
  margin-bottom: 30px;
  .logo {
    width: 80px;
    height: 80px;
  }
  h1 {
    font-size: 24px;
    color: ${(props) => props.theme.colors.black};
  }
  span {
    width: 60%;
    margin-top: 14px;
    text-align: center;
    font-size: 14px;
    font-family: ${(props) => props.theme.text.title.fontFamily};
    color: ${(props) => props.theme.colors.colorNeutralLight};
  }
`;
const Field = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 26px;
  img {
    max-width: 24px;
  }
  button {
    background: transparent;
    border: none;
    margin-right: 16px;
  }
`;
const Label = styled.span`
  display: block;
  color: #8798ad;
  background-color: #ffffff;
  font-family: ${(props) => props.theme.text.title.fontFamily};
  font-size: 14px;
  max-width: 80px;
  margin-bottom: -10px;
  margin-left: 10px;
  position: relative;
  z-index: 2px;
`;
const LabelPassword = styled.label`
  color: ${(props) => props.theme.colors.colorNeutralLight};
  font-family: ${(props) => props.theme.text.description.fontFamily};
  background-color: #ffffff;
  font-size: 13px;
  padding: 4px;
  text-align: center;

  max-width: 105px;
  margin-bottom: -10px;
  margin-left: 10px;
  position: relative;
  z-index: 2px;
`;
const ButtonStyled = styled.div`
  width: 10%;
  margin-top: -38px;
  margin-left: 90%;
  button {
    .view {
      max-width: 24px;
      height: 20px;
      fill: ${(props) => props.theme.colors.colorNeutralLight};
    }
    .hidden {
      max-width: 24px;
      height: 20px;
      fill: ${(props) => props.theme.colors.colorNeutralLight};
    }
  }
`;
const FeedbackError = styled.span`
  width: 100%;
  font-family: 'Rubik', sans-serif;
  font-size: 14px;
  font-weight: 400;
  color: #eb5230;
  text-align: left;
  white-space: nowrap;
  overflow: hidden;
  margin-top: 10px;
  padding-top: 14px;
`;
const Hr = styled.div`
  width: 100%;
  height: 1px;
  background-color: #e6eaf6;
  margin-top: 20px;
`;
const PasswordRequirements = styled.div`
  width: 101%;
  padding-top: 0px;
  ul {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    gap: 8px;
  }
`;
const Requirements = styled.li`
  display: flex;
  gap: 4px;
  align-items: center;
  width: 205px;
  padding: 2px;
`;

const CheckSign = styled.div<RequimentsPasswordProps>(
  ({ itsSafe }) => css`
    svg {
      fill: ${itsSafe === true ? ' #584AFE' : '#8798AD'};
    }
  `,
);

const SpanRequirement = styled.span<RequimentsPasswordProps>(
  ({ itsSafe }) => css`
    font-size: 13px;
    font-family: ${(props) => props.theme.text.title.fontFamily};
    color: ${itsSafe === true ? '#274264' : '#8798AD'};
  `,
);

const AcceptTerms = styled.div`
  width: 100%;
  margin-top: 28px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
  color: #8798ad;
  font-family: ${(props) => props.theme.text.title.fontFamily};
  font-size: 14px;
`;
const RememberMe = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;
const RecoverPassword = styled.a`
  text-decoration: none;
  color: ${(props) => props.theme.colors.primary};
  :hover {
    cursor: pointer;
  }
`;
const Button = styled.button`
  max-width: 416px;
  width: 100%;
  height: 49px;
  font-size: 14px;
  color: #ffffff;
  font-weight: 500;
  font-family: ${(props) => props.theme.text.title.fontFamily};
  background-color: ${(props) => props.theme.colors.primary};
  border: none;
  border-radius: 8px;
  transition: 0.3s;
  margin-top: 35px;
  :hover {
    cursor: pointer;
    filter: brightness(1.1);
  }
  &:disabled {
    cursor: not-allowed;
    opacity: 0.3;
  }
`;
