import axios from 'axios';
import { getBase64 } from './utils';

export const getAll = async () => {
  try {
    const response = await axios.get(
      process.env.REACT_APP_API_URL + '/list_files',
    );
    return response.data;
  } catch {
    throw new Error('Erro ao obter dados');
  }
};

export const uploadSingle = async (
  files: {
    name: string;
    size: number;
    type: string;
    lastModified: number;
    webkitRelativePath: string;
    lastModifiedDate: any;
  }[],
) => {
  const Images = await Promise.all(
    files.map(async (image) => {
      const base64 = await getBase64(image);
      const base64PlainText = base64?.toString().split(',')[1];

      return {
        file_name: image.name,
        file_content: base64PlainText,
        file_type: image.type,
      };
    }),
  );

  try {
    const response = await axios.post(
      process.env.REACT_APP_API_URL + '/upload_file',
      Images,
      {
        headers: {
          'Content-Type': 'application/json',
        },
      },
    );
    return response.data;
  } catch {
    throw new Error('Erro ao realizar upload!');
  }
};

export const login = async (email: string, password: string) => {
  try {
    const response = await axios.post(
      process.env.REACT_APP_API_URL + '/login',
      {
        email,
        password,
      },
    );
    return response;
  } catch {
    throw new Error('Erro ao realizar login!');
  }
};

export const uploadMultiple = async (array: any) => {
  const form = new FormData();
  array.map((image: any) => {
    form.append('file', image);
  });
  try {
    const response = await axios.post(
      process.env.REACT_APP_API_URL + '/upload/multiple',
      form,
      {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      },
    );
    return response.data;
  } catch {
    throw new Error('Erro ao realizar upload!');
  }
};
