export const getBase64 = (file: any): Promise<string | ArrayBuffer | null> => {
  return new Promise((resolve) => {
    let baseURL: string | ArrayBuffer | null = '';
    const reader = new FileReader();

    reader.readAsDataURL(file);

    reader.onload = () => {
      baseURL = reader.result;

      resolve(baseURL);
    };
  });
};

const imageTypes = ['jpg', 'png', 'jpeg', 'gif', 'x-png'];
const videoTypes = ['mp4', 'mov', 'wmv', 'avi', 'mkv'];

export const isImage = (url: string) => {
  const type = url.slice(-4).replace('.', '');

  return imageTypes.includes(type);
};

export const isVideo = (url: string) => {
  const type = url.slice(-4).replace('.', '');

  return videoTypes.includes(type);
};
